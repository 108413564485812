

$radius: 170px;
$quarter: ($radius / 2) + ($radius / 5.5);

@mixin balls($delay) {
    animation-fill-mode: both;
    position: absolute;
    opacity: 0;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 2px;
    animation: ball-spin-fade-loader 1s $delay infinite linear;
    @keyframes ball-spin-fade-loader {
        0%   {
            opacity: 0;
            transform: scale(0);
        }
        25%   {
            opacity: 0;
            transform: scale(0);
        }
        50% {
            opacity: 0.1;
            transform: scale(0.2);
        }
        70% {
            opacity: 0.3;
            transform: scale(0.5);
        }
        85% {
            opacity: 0.5;
            transform: scale(1);
        }
        100% {
            opacity: 0;
            transform: scale(0);
        }
    }
  }
.loading_page{
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .loader{
        width: 400px;
        height: 400px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        overflow: hidden;
        @media screen and (max-width: 768px) and (orientation:portrait){
            transform: scale(0.45);
        }
        @media screen and (max-height: 768px) and (orientation:landscape){
            transform: scale(0.45);
        }
        .balls {
            background-color: rgb(17, 17, 17);
            z-index: 3;
            .ball1 {
                @include balls(0.125s);
                top:$radius;
                left: 0;
            }
            .ball2 {
                @include balls(0.25s);
                top: $quarter/2;
                left: $quarter/2;
            }
            .ball3 {
                @include balls(0.385s);
                top:0;
                left: $radius;
            }
            .ball4 {
                @include balls(0.5s);
                top:$quarter/2;
                right: $quarter/2;
            }
            .ball5 {
                @include balls(0.625s);
                top:$radius;
                right: 0;
            }
            .ball6 {
                @include balls(0.75s);
                bottom: $quarter/2;
                right: $quarter/2;
            }
            .ball7 {
                @include balls(0.875s);
                bottom:0;
                right: $radius;
            }
            .ball8 {
                @include balls(1s);
                bottom:$quarter/2;
                left: $quarter/2;
            }
        }
    }
    
    .logoIcon{
        height: 120px;
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        opacity: 1;
        position: absolute;
        @media screen and (max-width: 768px) and (orientation:portrait){
            height: 70px;
        }
        @media screen and (max-height: 768px) and (orientation:landscape){
            height: 70px;
        }
        @keyframes loading {
            50%  {opacity:0.3}
            100% {opacity:1}
        }
    }
}


