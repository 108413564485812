$width : 768px;
$bg_color: #000825;
$str_color:rgb(60, 190, 112);
@mixin mobile_potrait{
    @media screen and (max-width: $width) and (orientation:portrait){
        @content
    }
};
@mixin mobile_landscape{
    @media screen and (max-height: $width) and (orientation:landscape){
        @content
    }
};

@mixin button1(){
    border: none;
    background-color: #117b9e;
    transition: all 0.3s ease;
    color:#fff;
    font-size: 2vh;
    border-radius: 3rem;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    @include mobile_potrait{
        font-size: 0.8rem;
        padding: 5px;
    }
    &:hover{
        background-color: #2cc5f8;
    }
    img{
        height: 40%;
        margin-right: 10px;
    }
}
button:disabled{
    background-color: #aaa !important;
    border-color: #aaa !important;
    cursor: not-allowed !important;
}
