@import "../../global.scss";
.termsPage{
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .sections{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
    }
}