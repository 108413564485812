@import "../../global.scss";
.mintModal{
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    &.active{
        display: flex;
    }
    @include mobile_potrait{
        height: 100vh;
        background-size: cover;
    }
    @include mobile_landscape{
        height: 100vh;
    }
    
    .modalContent{
        width: 70%;
        height: 70%;
        max-width: 820px;
        max-height: 640px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        margin-top: 3vh;
        background-color: rgba(0, 0, 0, 0.9);
        border: 3px #aaa solid;
        @include mobile_potrait{
            width: 90%;
            height: 50%;
        }
        @include mobile_landscape{
            margin-top: 3rem;
        }

        animation: modalAni 0.5s ease 0s 1 forwards;
        @keyframes modalAni {
            0%{
                transform: scale(0);
            }
            80%{
                transform: scale(1.2);
            }
            100%{
                transform: scale(1);
            }
        }
        .mintTop{
            width: 90%;
            height: 5rem;
            background-color: rgb(0, 0, 0);
            display: flex;
            align-items: center;
            justify-content:space-between;
            @include mobile_landscape{
                height: 4rem;
            }
            @include mobile_potrait{
                height: 4rem;
            }
            img{
                height: 70%;
                margin-left: 5%;
                @include mobile_landscape{
                    margin-left: 5%;
                }
                @include mobile_potrait{
                    margin-left: 15%;
                }
            }
            button{
                // margin-right: 10%;
                padding: 0.5rem 2rem;
                background-color: #117b9e;
                color: rgb(255, 255, 255);
                border: 1px #117b9e solid;
                border-radius: 2rem;
                font-size: 1.2rem;
                cursor: pointer;
                @include mobile_landscape{
                    font-size: 0.6rem;
                    padding: 0.3rem 1rem;
                }
                @include mobile_potrait{
                    font-size: 0.6rem;
                    padding: 0.3rem 1rem;
                }
            }
        }
        .modalWrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            height: calc(100% - 5rem);
            @include mobile_potrait{
                height: calc(100% - 4rem);
            }
            @include mobile_landscape{
                height: calc(100% - 4rem);
            }
            h1{
                width: 90%;
                color: white;
                text-align: center;
                font-size: 2rem;
                margin-bottom: 3vh;
                @include mobile_potrait{
                    font-size: 1.2rem;
                }
                @include mobile_landscape{
                    font-size: 1.2rem;
                }
            }
            

            .address{
                width: 90%;
                height: 30vh;
                background-color: rgb(199, 199, 199);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 2rem;
                padding: 1rem;
                @include mobile_potrait{
                    width: 90%;
                    height: 20vh;
                    padding: 0rem;
                    border-radius: 1rem;
                }
                @include mobile_landscape{
                    border-radius: 1rem;
                }
                p{
                    width: 90%;
                    color: black;
                    text-align: center;
                    margin-bottom: 2rem;
                    @include mobile_potrait{
                        font-size: 0.6rem;
                        margin-top: 1vh;
                        margin-bottom: 1vh;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                        margin-bottom: 0.5rem;
                    }
                }
                .addressInput{
                    width: 90%;
                    border: none;
                    text-align: center;
                    font-size: 1rem;
                    margin-bottom: 2vh;
                    background-color: rgb(199, 199, 199);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @include mobile_potrait{
                        font-size: 0.6rem;
                        margin-bottom: 1vh;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                    &:focus{
                        border: none;
                        border-color: rgb(199, 199, 199);
                        outline: none;
                    }
                }
                .state{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include mobile_potrait{
                        font-size: 0.6rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                    .statetext{
                        width: auto;
                        margin: 0;
                    }
                    .rect{
                        width: 1.5rem;
                        height: 1.5rem;
                        background-color: #117b9e;
                        
                        @include mobile_potrait{
                            width: 1rem;
                            height: 1rem;
                        }
                        @include mobile_landscape{
                            width: 1rem;
                            height: 1rem;
                        }
                    }

                }
                .coppyAddressButton{
                    background-color: #117b9e;
                    color: rgb(255, 255, 255);
                    border: 1px #117b9e solid;
                    border-radius: 2rem;
                    font-size: 1.2rem;
                    cursor: pointer;
                    margin-top: 2vh;
                    padding: 0.5rem 2rem;
                    transition: all 0.3s ease;
                    @include mobile_potrait{
                        font-size: 0.6rem;
                        margin-top: 1vh;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                }
                
            }
        }
    }
}
