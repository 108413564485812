@import "../../global.scss";
.about{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
    @include mobile_potrait{
        height: auto;
    }
    @include mobile_landscape{
        height: auto;
    }
    .aboutContent{
        width: 80%;
        display: flex;
        z-index: 1;
        align-items: center;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
            margin-top: 3rem;
        }
        @include mobile_landscape{
            flex-direction: column;
            margin-top: 3rem;
        }
        .left{
            flex:0.6;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h1{
                font-size: 4rem;
                font-family: 'Oswald', sans-serif;
                color: #223049;
                width: 90%;
                margin-bottom: 1rem;
                text-align: center;
                @include mobile_potrait{
                    font-size: 2rem;
                    text-align: center;
                }
                @include mobile_landscape{
                    font-size: 2rem;
                }
            }
            p{
                font-size: 1.1rem;
                font-family: 'Dongle', sans-serif;
                color: #223049;
                width: 90%;
                margin-bottom: 0.5rem;
                @include mobile_potrait{
                    font-size: 1rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
                a{
                    text-decoration: none;
                    color: #223049;
                    font-weight: 600;
                }
            }
            .family{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    height: 10rem;
                    @include mobile_potrait{
                        height: 5rem;
                        margin-bottom: 2rem;
                    }
                    @include mobile_landscape{
                        height: 5rem;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
        .right{
            flex:0.4;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img{
                width: 100%;
                @include mobile_potrait{
                    width: 80%;
                }
                @include mobile_landscape{
                    width: 70%;
                }
            }
            
        }
        div{
          .nodeContent{
              h2{
                  font-size: 4vh;
              }
          }  
        }
    }
}