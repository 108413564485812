@import "../../global.scss";
.mintCountModal{
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    &.active{
        display: flex;
    }
    @include mobile_potrait{
    }
    @include mobile_landscape{
    }
    .modalContent{
        width: 70%;
        height: 70%;
        max-width: 820px;
        max-height: 640px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        margin-top: 3vh;
        background-color: rgba(0, 0, 0, 0.9);
        border: 3px #aaa solid;
        @include mobile_potrait{
            width: 90%;
            height: 80%;
        }
        @include mobile_landscape{
            margin-top: 3rem;
        }

        animation: modalAni 0.5s ease 0s 1 forwards;
        @keyframes modalAni {
            0%{
                transform: scale(0);
            }
            80%{
                transform: scale(1.2);
            }
            100%{
                transform: scale(1);
            }
        }

        .mintTop{
            width: 100%;
            height: 5rem;
            background-color: rgba(36, 36, 36, 0.26);
            display: flex;
            align-items: center;
            justify-content:space-between;
            @include mobile_landscape{
                height: 4rem;
            }
            @include mobile_potrait{
                height: 4rem;
            }
            img{
                height: 70%;
                margin-left: 5%;
                @include mobile_landscape{
                    margin-left: 5%;
                }
                @include mobile_potrait{
                    margin-left: 15%;
                }
            }
            button{
                margin-right: 10%;
                padding: 0.5rem 2rem;
                background-color: #117b9e;
                color: rgb(255, 255, 255);
                border: 1px #117b9e solid;
                border-radius: 2rem;
                font-size: 1.2rem;
                cursor: pointer;
                @include mobile_landscape{
                    font-size: 0.6rem;
                }
                @include mobile_potrait{
                    font-size: 0.6rem;
                }
            }
        }
        
        .modalWrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            height: calc(100% - 5rem);
            width: 100%;
            @include mobile_landscape{
                
            }
            @include mobile_potrait{
                flex-direction: column;
            }
            .left{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img{
                    width: 70%;
                    border-radius: 1rem;
                    @include mobile_landscape{
                
                    }
                    @include mobile_potrait{
                        width: 60%;
                    }
                }
            }
            .right{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h1{
                    width: 80%;
                    color: white;
                    text-align: center;
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    @include mobile_potrait{
                        font-size: 1.2rem;
                    }
                    @include mobile_landscape{
                        font-size: 1.2rem;
                    }
                }
                .mintRadio{
                    width: 70%;
                    height: 4vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 2rem;
                    margin-top: 3vh;
    
                    input{
                        margin-right: 2rem;
                        cursor: pointer;
                        @include mobile_potrait{
                            font-size: 1rem;
                            margin-right: 1rem;
                        }
                        @include mobile_landscape{
                            font-size: 1rem;
                        }
                    }
                    span{
                        color: white;
                        margin-right: 2rem;
                        margin-bottom: 0rem;
                        @include mobile_potrait{
                            font-size: 1rem;
                            margin-right: 1rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                        }
                    }
                }
                p{
                    color: white;
                    font-size: 1.2rem;
                    margin-top: 3vh;
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
                button{
                    // height: 4vh;
                    margin-top: 4vh;
                    background-color: #117b9e;
                    color: rgb(255, 255, 255);
                    border: 1px #117b9e solid;
                    border-radius: 3rem;
                    font-size: 1.2rem;
                    padding: 15px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    &:hover{
                        background-color: #2cc5f8;
                    }
                    @include mobile_potrait{
                        padding: 5px;
                        font-size: 0.8rem;
                        height: 2rem;
                    }
                    @include mobile_landscape{
                        padding: 10px;
                        font-size: 0.8rem;
                    }
                }
            }

            
            
        }
        span{
            // margin-top: 2vh;
            margin-bottom: 2rem;
            color: #fff;

            @include mobile_potrait{
                font-size: 0.6rem;
            }
            @include mobile_landscape{
                font-size: 0.6rem;
            }
            a{
                text-decoration: none;
                color: #117b9e;
                font-weight: 700;
            }
            input{
                margin-right: 0.5rem;
            }
        }
    }
        
}
