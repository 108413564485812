@import "../../global.scss";
.mint{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-color: #fff;
    @include mobile_potrait{
        height: 100vh;
        background-size: cover;
    }
    @include mobile_landscape{
        height: 100vh;
    }
    
    .mintContent{
        width: 90%;
        // height: 50%;
        display: flex;
        @include mobile_potrait{
            flex-direction: column-reverse;
            width: 80%;
            height: 80%;
            align-items: center;
            justify-content: center;
        }
        .mintLeft{
            flex:0.5;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile_potrait{
                margin-bottom: 5vh;
            }
            img{
                width: 100%;
                border-radius: 3rem;
                @include mobile_potrait{
                    width: 100%;
                }
            }
        }
        .mintRight{
            flex: 0.5;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            .mintTitle{
                font-size: 4rem;
                font-weight: 600;
                width: 80%;
                color: #223049;
                font-family: 'Oswald', sans-serif;
                text-align: center;
                @include mobile_potrait{
                    font-size: 2rem;
                }
                @include mobile_landscape{
                    font-size: 2rem;
                }
            }
            .mintDate{
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                p{
                    text-align: center;
                    font-size: 1.5rem;
                    color: #223049;
                    text-align: center;
                    @include mobile_potrait{
                        font-size: 1rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }

                }
                
            }
            .mintText{
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                p{
                    text-align: center;
                    font-size: 1.5rem;
                    color: #223049;
                    @include mobile_potrait{
                        font-size: 1rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
            }
            .mintButton{
                @include button1();
                width: 20vh;
                @include mobile_potrait{
                    font-size: 1vh;
                    width: 20vh;
                    height: 4.5vh;
                }
                @include mobile_landscape{
                    width: 20vh;
                }
                &:hover{
                    opacity: 0.8;
                    transform: scale(1.02);
                }
                img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    
                }
                span{
                    width: 100%;
                    font-size: 3vh;
                    font-weight: 600;
                    z-index: 1;
                    text-align: center;
                    @include mobile_potrait{
                        font-size: 2vh;
                    }
                }
            }
        }
    }

}
