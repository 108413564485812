@import "../../global.scss";
.roadmap{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    .roadmapTitle{
        width: 60%;
        margin-top: 5rem;
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile_potrait{
            width: 90%;
        }
        
        h1{
            font-size: 4rem;
            color: #223049;
            width: 100%;
            text-align: center;
            font-family: 'Oswald', sans-serif;
            @include mobile_potrait{
                font-size: 2rem;
            }
            @include mobile_landscape{
                font-size: 2rem;
            }
        }
    }
    .roadmapContent{
        width: 100%;
        
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 5rem;
        @include mobile_potrait{
        }
        @include mobile_landscape{
        }
        .node{
            width: 100%;
            display: flex;
            margin-bottom: -15vh;
            @include mobile_potrait{
                flex-direction: column;
                margin-bottom: 0vh;
            }
            .nodeLeft{
                flex:0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                
                @include mobile_potrait{
                    width: 100%;
                }
                img{
                    width: 100%;
                }
                h2{
                    width: 90%;
                    font-size: 3rem;
                    color: #223049;
                    margin-top: 2vh;
                    margin-bottom: 2vh;
                    margin-right: -50vw;
                    font-family: 'Oswald', sans-serif;
                    @include mobile_potrait{
                        font-size: 1.5rem;
                        margin-right: 0vw;
                    }
                    @include mobile_landscape{
                        font-size: 1.5rem;
                    }
                }
                .nodeContent{
                    border-top-right-radius: 2vh;
                    border-bottom-left-radius: 2vh;
                    border-bottom-right-radius: 2vh;
                    width: 80%;
                   
                    h3{
                        width: 90%;
                        font-size: 2rem;
                        color: #223049;
                        font-family: 'Oswald', sans-serif;
                        margin-top: 2vh;
                        margin-bottom: 2vh;
                        @include mobile_potrait{
                            font-size: 1rem;
                            margin-bottom: 0.5rem;
                        }
                        @include mobile_landscape{
                            font-size: 1rem;
                            margin-bottom: 0.5rem;
                        }
                    }
                    p{
                        width: 90%;
                        font-size: 1.5rem;
                        color: #223049;
                        margin-bottom: 2vh;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                            margin-bottom: 0.5rem;
                        }
                        a{
                            text-decoration: none;
                            color: #223049;
                            font-weight: 600;
                        }
                    }
                }
                .first{
                    @include mobile_potrait{
                        margin-right: 0vw;
                    }
                    @include mobile_landscape{
                        width: 100%;
                    }
                }
                .second{
                    margin-right: 2vw;
                    @include mobile_potrait{
                        margin-right: 0vw;
                    }
                }
                .third{
                    margin-right: -30vw;
                    @include mobile_potrait{
                        margin-right: 0vw;
                    }
                    @include mobile_landscape{
                        width: 100%;
                    }
                }
            }
            
            .nodeRight{
                flex:0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                
                img{
                    width: 100%;
                    z-index: -1;
                }
                h2{
                    width: 90%;
                    font-size: 3rem;
                    color: #223049;
                    margin-top: 2vh;
                    margin-bottom: 2rem;
                    margin-left: -30vw;
                    font-family: 'Oswald', sans-serif;
                    @include mobile_potrait{
                        font-size: 1.5rem;
                        width: 80%;
                        margin-left: 0vw;
                    }
                    @include mobile_landscape{
                        font-size: 1.5rem;
                        width: 80%;
                        margin-bottom: 0.5rem;
                    }
                }
                .nodeContent{
                    border-top-right-radius: 2vh;
                    border-bottom-left-radius: 2vh;
                    border-bottom-right-radius: 2vh;
                    width: 80%;
                    margin-bottom: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    z-index: 1;
                    h3{
                        width: 90%;
                        font-size: 2rem;
                        color: #223049;
                        font-family: 'Oswald', sans-serif;
                        margin-bottom: 1rem;
                        @include mobile_potrait{
                            font-size: 1rem;
                            margin-bottom: 0.5rem;
                        }
                        @include mobile_landscape{
                            font-size: 1rem;
                            margin-bottom: 0.5rem;
                        }
                    }
                    p{
                        width: 90%;
                        font-size: 1.5rem;
                        color: #223049;
                        margin-bottom: 1rem;
                        
                        @include mobile_potrait{
                            font-size: 0.8rem;
                            margin-bottom: 0.5rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                            margin-bottom: 0.5rem;
                        }
                        a{
                            text-decoration: none;
                            color: #223049;
                            font-weight: 600;
                        }
                    }
                    // .firstTxt1{
                    //     margin-left: -20vw;
                    // }
                    // .firstTxt2{
                    //     margin-left: -15vw;
                    // }
                    // .firstTxt3{
                    //     margin-left: -5vw;
                    // }
                    // .firstTxt5{
                    //     margin-left: -5vw;
                    // }
                    // .firstTxt6{
                    //     margin-left: -25vw;
                    // }
                    // .firstTxt7{
                    //     margin-left: -40vw;
                    // }
                }
                .first{
                    // margin-left: -10vw;
                    @include mobile_potrait{
                        margin-left: 0vw;
                    }
                    @include mobile_landscape{
                        width: 100%;
                    }
                }
                .second{
                    margin-left: -20vw;
                    
                    @include mobile_potrait{
                        margin-left: 0vw;
                    }
                    @include mobile_landscape{
                        margin-left: -20vw;
                    }
                }
                .third{
                    margin-left: -10vw;
                    @include mobile_potrait{
                        margin-left: 0vw;
                    }
                    @include mobile_landscape{
                        width: 100%;
                    }
                }
            }
        }
        .mobNode{
            @include mobile_potrait{
                flex-direction: column-reverse;
            }
            @include mobile_landscape{
            }
        }
    }
}