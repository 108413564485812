@import "../../global.scss";
.topbar{
    width: 100%;
    height: 7rem;
    background-color: #00000083;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top:0;
    z-index: 15;
    position: fixed;

    backdrop-filter: blur(5px);
    @include mobile_potrait{
        height: 5rem;
    }
    @include mobile_landscape{
        height: 4rem;
    }
    .logo{
        overflow: hidden;
        margin-left: 5rem;
        position: relative;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include mobile_potrait{
            margin-left: 1rem;
        }
        @include mobile_landscape{
            margin-left: 1rem;
        }
        a{
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: 6rem;
                @include mobile_potrait{
                    height: 4rem;
                }
                @include mobile_landscape{
                    height: 3rem;
                }
            }
        }
        h1{
            font-size: 4rem;
            color: #223049;
            font-family: 'Oswald', sans-serif;
            @include mobile_potrait{
                font-size: 2rem;
            }
            @include mobile_landscape{
                font-size: 2rem;
            }
        }
    }
    .navList{
        height: 6vh;
        display: flex;
        align-items: center;
        width: 70%;
        justify-content: flex-end;
        margin-right: 5rem;
        overflow: hidden;
        @include mobile_potrait{
            display: none;
        }
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            overflow: hidden;
            li{
                margin-right: 3vh;
                cursor: pointer;
                a{
                    color: #fff;
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    font-family: 'Oswald', sans-serif;
                    &:hover{
                        opacity: 0.7;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
    
    .hamburger{
        display: none;
        @include mobile_potrait{
            display: flex;
            width: 2rem;
            height: 2rem;
            position: absolute;
            right : 20px;
            top : 1.5rem;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
        }
        @include mobile_landscape{
            display: none;
        }
        span{
            width: 100%;
            height: 0.2rem;
            border-radius: 3px;
            background-color: #223049;
            transform-origin: left;
            transition: all 1s ease;
        }
        &.active{
            z-index: 1;
            span{
                &:first-child{
                    width: 2.2rem;
                    transform: rotate(35deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    width: 2.2rem;
                    transform: rotate(-35deg);
                }

            }
        }
    }
}