@import "../../global.scss";
.home{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    z-index: 3;
    background-color: $bg_color;
    position: relative;
    @include mobile_potrait{
        height: 100vh;
    }
    .homeContent{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: 100%;
        @include mobile_landscape{
        }
        @include mobile_potrait{
        }
        
        .homeWrapper{
            width: 90%;
            height: 60vh;
            display: flex;
            z-index: 1;
            @include mobile_potrait{
                height: 80vh;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .homeLeft{
                flex:0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include mobile_potrait{
                    flex:0.4;
                    width: 90%;
                    margin-bottom: 5vh;
                }
                h1{
                    font-size: 4.5rem;
                    font-weight: 800;
                    color: #223049;
                    margin-bottom: 2vh;
                    font-family: 'Oswald', sans-serif;
                    width: 100%;
                    line-height: 1;
                    @include mobile_potrait{
                        font-size: 2.5rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 2.5rem;
                    }
                    span{
                        color: #117b9e;
                        font-family: 'Oswald', sans-serif;
                    }
                }
                p{
                    font-size: 2rem;
                    
                    color:rgb(0, 0, 0);
                    margin-bottom: 5vh;
                    @include mobile_potrait{
                        margin-bottom: 1rem;
                        font-size: 0.8rem;
                        text-align: center;
                        font-weight: 500;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
                .buttons{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    @include mobile_potrait{
                        justify-content: center;
                    }
                    a{
                        width: 15rem;
                        margin-right: 3%;
                        height: 3.5rem;
                        border: 3px #117b9e solid;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.5rem;
                        text-decoration: none;
                        transition: all 0.3s ease;
                        font-weight: 800;
                        @include mobile_landscape{
                            width: 50%;
                            font-size: 0.8rem;
                            height: 2rem;
                        }
                        @include mobile_potrait{
                            font-size: 0.8rem;
                            width: 50%;
                            height: 2rem;
                            border: 2px #117b9e solid;
                        }
                    }
                    .discord{
                        color: #fff;
                        background-color: #117b9e;
                        &:hover{
                            background-color: #33c7f8;
                        }
                    }
                    .twitter{
                        color: #117b9e;
                        background-color: #00000000;
                        &:hover{
                            border: 3px #33c7f8 solid;
                            color: #33c7f8;
                        }
                    }
                    
                }
            }
            .homeRight{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;
                position: relative;
                img{
                    width: 90%;
                    position: absolute;
                    @include mobile_landscape{
                        width: 80%;
                    }
                }
                .countDown{
                    width: 80%;
                    height: 15%;
                    background-color: rgba(255, 255, 255, 0.679);
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    border-bottom-left-radius: 1vh ;
                    border-bottom-right-radius: 1vh ;
                    z-index: 1;
                }
            }
        }
        .bg{
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            @include mobile_landscape{
            }
            @include mobile_potrait{
                object-position: 50%;
            }
        }
    }
}