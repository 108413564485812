@import "../../global.scss";
.terms{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    @include mobile_potrait{
        height: auto;
    }
    @include mobile_landscape{
        height: auto;
    }
    .termsContent{
        width: 80%;
        display: flex;
        z-index: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 7rem;
        margin-bottom: 5rem;
        max-width: 1200px;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
            margin-top: 6rem;
        }
        @include mobile_landscape{
            flex-direction: column;
            margin-top: 3rem;
        }
        h1{
            font-size: 4rem;
            font-family: 'Oswald', sans-serif;
            color: #223049;
            width: 100%;
            margin-bottom: 1rem;
            @include mobile_potrait{
                font-size: 2rem;
                text-align: center;
            }
            @include mobile_landscape{
                font-size: 2rem;
            }
        }
        h2{
            font-size: 2rem;
            font-family: 'Oswald', sans-serif;
            color: #223049;
            width: 100%;
            margin-bottom: 1rem;
            @include mobile_potrait{
                font-size: 1.5rem;
                text-align: center;
            }
            @include mobile_landscape{
                font-size:  1.5rem;
            }
        }
        p{
            font-size: 1.1rem;
            font-family: 'Dongle', sans-serif;
            color: #223049;
            width: 100%;
            margin-bottom: 1rem;
            @include mobile_potrait{
                font-size: 1rem;
            }
            @include mobile_landscape{
                font-size: 0.8rem;
            }
            a{
                text-decoration: none;
                color: #223049;
                font-weight: 600;
            }
        }
        ul{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            li{
                font-size: 1.1rem;
                font-family: 'Dongle', sans-serif;
                color: #223049;
                width: 100%;
                margin-bottom: 1rem;
                @include mobile_potrait{
                    font-size: 1rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
                a{
                    text-decoration: none;
                    color: #223049;
                    font-weight: 600;
                } 
            }
        }
    }
}