@import "../../global.scss";
.footer{
    width: 100%;
    height: 30vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #223049;
    @include mobile_potrait{
        height: 10vh;
    }
    .footerContent{
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        .socialLinks{
            width: 100%;
            display: flex;
            align-items:center;
            justify-content: center;
            .socialIcon{
                margin: 5vh;
                transition: all 0.4s ease;
                @include mobile_potrait{
                    margin: 2vh;
                }
                a{
                    font-size: 6vh;
                    color:white;
                    @include mobile_potrait{
                        font-size: 3vh;
                    }
                    img{
                        height: 5rem;
                        filter: grayscale(1);
                        @include mobile_potrait{
                            height: 2rem;
                        }
                        @include mobile_landscape{
                            height: 2rem;
                        }
                    }
                }
                &:hover{
                    opacity: 0.8;
                }
            }
        }
        p{
            font-size: 2vh;
            color:white;
            @include mobile_potrait{
                font-size: 1.5vh;
                margin-bottom: 2vh;
            }
        }
    }
}