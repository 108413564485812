@import "../../global.scss";
.sidebar{
    width: 150px;
    height: 110vh;
    position: fixed;
    right: -150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 1s ease;
    background-color: #0008258c;
    z-index: 3;
    position: fixed;
    top: 6vh;
    @media screen and (max-width: 972px) {
        width: 150px;
        right:-150px;
    }
    @include mobile_landscape{
        display: none;
    }
    &.active{
        right: 0;
    }
    
    ul{
        margin-top: 10vh;
        padding: 0;
        list-style: none;
        
        font-weight: 400;
        color:white;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @include mobile_potrait{
            font-size: 2vh;
            margin-top: 6vh;
        }
        .menuItem1{
            transition: right 1s ease;
            transition-delay: 0.1s;
        }
        .menuItem2{
            transition:  right 1s ease;
            transition-delay: 0.2s;
        }
        .menuItem3{
            transition:  right 1s ease;
            transition-delay: 0.3s;
        }
        .menuItem4{
            transition:  right 1s ease;
            transition-delay: 0.4s;
        }
        .menuItem5{
            transition:  right 1s ease;
            transition-delay: 0.5s;
        }
        li{
            position: relative;
            
            right:-400px;
            transition: right 0s ease;
            @media screen and (max-width: 972px) {
                right:-200px;
                margin-bottom: 3vh;
            }
            a{
                font-size: inherit;
                font-family: 'Roboto', sans-serif;
                color: inherit;
                text-decoration: none;
                .socialLinks{
                    margin-right: 20px;
                    font-size: 2vh;
                }
            }
            &:hover{
                font-weight: 700;
            }
            
            &.active{
                right:0;
            }
        }
        
    }
} 